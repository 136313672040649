import { Injectable } from '@angular/core';

@Injectable()
export class SpryntService {
  constructor() {}

  /**
   * Read the given file as url
   *
   * @param file
   */
  public readAsBase64 = (file: File): Promise<any> =>
    // Return a new promise
    new Promise((resolve, reject) => {
      // Create a new reader
      const reader: any = new FileReader();

      // Resolve the promise on success
      reader.onload = () => {
        resolve(btoa(reader.result));
      };

      // Reject the promise on error
      reader.onerror = (e) => {
        reject(e);
      };

      // Read the file as the
      reader.readAsBinaryString(file);
    });

  /**
   * Read the given file as url
   *
   * @param file
   */
  public readAsDataURL = async (file: File | string): Promise<any> => {
    let blob: File | Blob;

    if (typeof file === 'string') {
      const res = await fetch(file);
      blob = await res.blob();
    } else {
      blob = file;
    }

    // Return a new promise
    return new Promise((resolve, reject) => {
      // Create a new reader
      const reader = new FileReader();

      // Resolve the promise on success
      reader.onload = () => {
        resolve(reader.result);
      };

      // Reject the promise on error
      reader.onerror = (e) => {
        reject(e);
      };

      // Read the file as the
      reader.readAsDataURL(blob);
    });
  };

  cloneDeep = (obj: any) => {
    if (obj) obj = JSON.parse(JSON.stringify(obj));
    return obj;
  };
}
