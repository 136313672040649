import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, of, ReplaySubject } from 'rxjs';
import { SpryntService } from '../services';
import { defaultNavigation } from './data';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Constructor
   */
  constructor(private _spryntService: SpryntService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    const nav: Navigation = {
      default: this._spryntService.cloneDeep(this._defaultNavigation),
    };
    this._navigation.next(nav);
    return of(nav);
  }
}
