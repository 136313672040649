import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IconsModule } from 'app/core/icons/icons.module';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from './helpers';
import {
  AuthService,
  CustomSnackbarService,
  LocalStorageRefService,
  LocalStorageService,
  SpryntService,
  UserService,
} from './services';

@NgModule({
  imports: [MatSnackBarModule, IconsModule],
  providers: [
    AuthService,
    UserService,
    CustomSnackbarService,
    LocalStorageRefService,
    LocalStorageService,
    SpryntService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UserService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
