<!-- Button -->
<div
  class="mr-2 flex felx-row items-center cursor-pointer gap-2 p-2 rounded-md hover:bg-hover"
  matRipple
  [matRippleDisabled]="false"
  [matRippleUnbounded]="false"
  [matMenuTriggerFor]="userActions"
>
  <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  <div class="text-lg font-semibold">{{ user?.name }}</div>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <span class="flex flex-col leading-none p-3">
    <span>Signed in as</span>
    <span class="mt-1.5 text-md font-medium">{{ user?.email }}</span>
  </span>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
