import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService, CustomSnackbarService } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private _router: Router,
    private _customSnackbarService: CustomSnackbarService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //skip interceptor who have header as skip
    if (request.headers.get('skip')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        let errorTxt = error.message || error.statusText;
        if (error.error && error.error.message) {
          errorTxt = error.error.message;
        }

        if ([401, 403].includes(error.status)) {
          // auto logout if 401 or 403 response returned from api
          if (this.authenticationService.jwtToken) {
            this._router.navigate(['/sign-out']);
            this._customSnackbarService.open(errorTxt, 'error', 'Ok');
          } /* else {
            // When the token itself is not there, redirect to login
            this._router.navigate(['/sign-in']);
          } */
        }

        return throwError(errorTxt);
      })
    );
  }
}
