import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SchemeToggleComponent } from './schemeToggle.component';

@NgModule({
  declarations: [SchemeToggleComponent],
  imports: [CommonModule, MatIconModule, MatMenuModule, MatRippleModule, MatTooltipModule, MatButtonModule],
  exports: [SchemeToggleComponent],
})
export class SchemeToggleModule {}
