import { Injectable } from '@angular/core';
import { LocalStorageRefService } from './local-storage-ref.service';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private _localStorage: Storage;

  constructor(_localStorageRefService: LocalStorageRefService) {
    this._localStorage = _localStorageRefService.localStorage;
  }

  set(key: string, data: any): void {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    this._localStorage.setItem(key, data);
  }

  get(key: string): any {
    let data = this._localStorage.getItem(key);
    if (data && typeof data !== 'string') {
      data = JSON.parse(data);
    }
    return data;
  }

  remove(key: string) {
    this._localStorage.removeItem(key);
  }

  clearAllLocalStorage(): void {
    this._localStorage.clear();
  }
}
