/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'mat_outline:dashboard',
    link: '/dashboard',
  },
  {
    id: 'users',
    title: 'Users',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: '/users',
  },
];
