import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../models';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';

interface AuthResponse {
  message: string;
  user: User;
  token: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _jwtToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private _http: HttpClient,
    private _userService: UserService,
    private _localStorageService: LocalStorageService
  ) {}

  set jwtToken(value: string) {
    this._jwtToken.next(value);
    this._localStorageService.set('token', value);
  }

  public get jwtToken(): string {
    return this._jwtToken.value || this._localStorageService.get('token');
  }

  signIn(credentials: { email: string; password: string }): Observable<AuthResponse> {
    return this._http.post<AuthResponse>(`${environment.apiurl}auth/adminSignin`, credentials).pipe(
      tap((response) => {
        this._userService.user = response.user;
        this.jwtToken = response.token;
      })
    );
  }

  signOut() {
    this._userService.user = null;
    this.jwtToken = null;
    this._localStorageService.remove('token');
  }
}
