import { UserService } from '../services';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function appInitializer(_userService: UserService) {
  return () =>
    new Promise((resolve) => {
      // attempt to get user on app start up to auto authenticate
      _userService
        .getCurrentUser()
        .subscribe()
        .add(resolve as any);
    });
}
