<!-- Button -->
<div
  class="mr-2 flex felx-row items-center cursor-pointer gap-2 p-2 rounded-md hover:bg-hover"
  matRipple
  [matRippleDisabled]="false"
  [matRippleUnbounded]="false"
  [matMenuTriggerFor]="schemes"
>
  <div
    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover ring-2"
    *ngIf="config.scheme === 'auto'"
  >
    <div class="flex items-center rounded-full overflow-hidden">
      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>
    </div>
    <div class="flex items-center ml-2 font-medium leading-5">Auto</div>
  </div>

  <div
    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover ring-2"
    *ngIf="config.scheme === 'light'"
  >
    <div class="flex items-center rounded-full overflow-hidden">
      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
    </div>
    <div class="flex items-center ml-2 font-medium leading-5">Light</div>
  </div>

  <div
    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover ring-2"
    *ngIf="config.scheme === 'dark'"
  >
    <div class="flex items-center rounded-full overflow-hidden">
      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
    </div>
    <div class="flex items-center ml-2 font-medium leading-5">Dark</div>
  </div>
</div>

<mat-menu #schemes="matMenu">
  <div class="flex flex-col gap-3 justify-items-start p-5">
    <!-- Auto -->
    <div
      class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
      [class.ring-2]="config.scheme === 'auto'"
      matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query."
      matTooltipClass="tooltip-size"
      (click)="setScheme('auto')"
      mat-menu-item
    >
      <div class="flex items-center rounded-full overflow-hidden">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>
      </div>
      <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme !== 'auto'">
        Auto
      </div>
    </div>
    <!-- Dark -->
    <div
      class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
      [class.ring-2]="config.scheme === 'dark'"
      (click)="setScheme('dark')"
      mat-menu-item
    >
      <div class="flex items-center rounded-full overflow-hidden">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
      </div>
      <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme !== 'dark'">
        Dark
      </div>
    </div>
    <!-- Light -->
    <div
      class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
      [class.ring-2]="config.scheme === 'light'"
      (click)="setScheme('light')"
      mat-menu-item
    >
      <div class="flex items-center rounded-full overflow-hidden">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
      </div>
      <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme !== 'light'">
        Light
      </div>
    </div>
  </div>
</mat-menu>
