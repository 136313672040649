import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _allUserData: BehaviorSubject<{
    data: User[];
    totalPages: number;
    totalItems: number;
    page: number;
    morePages: boolean;
  } | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set user(value: User) {
    this._user.next(value);
  }

  public get user$(): Observable<User> {
    return this._user.asObservable();
  }

  public get userObj(): User {
    return this._user.value;
  }

  get allUserData$(): Observable<{
    data: User[];
    totalPages: number;
    totalItems: number;
    page: number;
    morePages: boolean;
  }> {
    return this._allUserData.asObservable();
  }

  getCurrentUser(): Observable<{ user: User }> {
    return this._httpClient.get<{ user: User }>(environment.apiurl + 'user').pipe(
      tap((response) => {
        this._user.next(response.user);
      })
    );
  }

  getUsers(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    search = '',
    role = '',
    filter = '',
    isDeleted = false,
    showDeactivated = false,
    showOnlyActive = false,
    showUnVerifiedUsers = false
  ): Observable<{
    data: User[];
    totalPages: number;
    totalItems: number;
    page: number;
    morePages: boolean;
  }> {
    return this._httpClient
      .get<{
        data: User[];
        totalPages: number;
        totalItems: number;
        page: number;
        morePages: boolean;
      }>(environment.apiurl + 'user/all', {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          search,
          filter,
          isDeleted,
          showDeactivated,
          showOnlyActive,
          showUnVerifiedUsers,
          role,
        },
      })
      .pipe(
        tap((response) => {
          this._allUserData.next(response);
        })
      );
  }

  updateUsersState(val: any) {
    this._allUserData.next(val);
  }

  getDashboardData(): Observable<{
    userCounts: any;
  }> {
    return this._httpClient.get<{
      userCounts: any;
    }>(environment.apiurl + 'user/dashboardData');
  }

  sendMassInfo(data: any) {
    return this._httpClient.post<any>(environment.apiurl + 'user/sendMassInformation', data);
  }

  exportUsers(
    userIds: any[],
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    search = '',
    role = '',
    filter = '',
    isDeleted = false,
    showDeactivated = false,
    showOnlyActive = false,
    showUnVerifiedUsers = false
  ): Observable<any> {
    return this._httpClient
      .post(
        environment.apiurl + 'user/export',
        { userIds },
        {
          params: {
            pageNo,
            size,
            sortBy,
            sortDirection,
            search,
            filter,
            isDeleted,
            showDeactivated,
            showOnlyActive,
            showUnVerifiedUsers,
            role,
          },
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        map((res) => {
          // Extract content disposition header
          const contentDisposition = res.headers.get('Content-Disposition');

          // Extract the file name
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();

          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename,
          };
          return data;
        })
      );
  }
}
